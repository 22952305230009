export const formatCurrency =  (v , digits = 2) => new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: digits,
  maximumFractionDigits: digits,
}).format( v || 0);

export const ccNumberInputInputHandler = (e) => {
  const unmask = (value) => value.replace(/[^\d]/g, "");

  const ccNumberPattern = /^\d{0,16}$/g;
  let ccNumberSeparator = " ";
  let ccNumberInputOldValue = e.target.value;
  let ccNumberInputOldCursor = e.target.selectionEnd;
  let el = e.target;
  let newValue = unmask(el.value);
  let newCursorPosition;

  const checkSeparator = (position, interval) =>
    Math.floor(position / (interval + 1));
  const mask = (value, limit, separator) => {
    var output = [];
    for (let i = 0; i < value.length; i++) {
      if (i !== 0 && i % limit === 0) {
        output.push(separator);
      }

      output.push(value[i]);
    }

    return output.join("");
  };

  if (newValue.match(ccNumberPattern)) {
    newValue = mask(newValue, 4, ccNumberSeparator);

    newCursorPosition =
      ccNumberInputOldCursor -
      checkSeparator(ccNumberInputOldCursor, 4) +
      checkSeparator(
        ccNumberInputOldCursor +
          (newValue.length - ccNumberInputOldValue.length),
        4
      ) +
      (unmask(newValue).length - unmask(ccNumberInputOldValue).length);

    el.value = newValue !== "" ? newValue : "";
  } else {
    el.value = ccNumberInputOldValue;
    newCursorPosition = ccNumberInputOldCursor;
  }

  return el.value;
};


export const rountingNumberInputHandler = (e) => {

  if (e.target.value.length > 11) return e.target.value.slice(0, -1)
  const unmask = (value) => value.replace(/[^\d]/g, "");

  const rountingNumberPattern = /^\d{0,9}$/g;
  let rountingNumberSeparator = " ";
  let rountingNumberInputOldValue = e.target.value;
  let rountingNumberInputOldCursor = e.target.selectionEnd;
  let el = e.target;
  let newValue = unmask(el.value);
  let newCursorPosition;

  const checkSeparator = (position, interval) =>
    Math.floor(position / (interval + 1));
  const mask = (value, limit, separator) => {
    var rountingNumberOutput = [];
    for (let i = 0; i < value.length; i++) {
      if (i !== 0 && i % limit === 0) {
        rountingNumberOutput.push(separator);
      }

      rountingNumberOutput.push(value[i]);
    }

    return rountingNumberOutput.join("");
  };

  if (newValue.match(rountingNumberPattern)) {
    newValue = mask(newValue, 3, rountingNumberSeparator);

    newCursorPosition =
      rountingNumberInputOldCursor -
    checkSeparator(rountingNumberInputOldCursor, 3) +
      checkSeparator(
        rountingNumberInputOldCursor +
        (newValue.length - rountingNumberInputOldValue.length),
        3
      ) +
    (unmask(newValue).length - unmask(rountingNumberInputOldValue).length);

    el.value = newValue !== "" ? newValue : "";
  } else {
    el.value = rountingNumberInputOldValue;
    newCursorPosition = rountingNumberInputOldValue;
  }

  return el.value;
};
