import React from "react";
import { Container, Icon } from "..";
import css from "./style.module.scss";

export default (props) => (
  <Container {...props} className={`${css.avatarContainer} ${props.className}`}>
    <Container className={css.avatarInnerContainer}>
      {props.initials ?       <Container className={ css.initials}>
        
        {props.initials}
      </Container> 
      : props.src ? <img
        className={`${props.round ? css.round : ""} ${
          props.border ? css.border : ""
        }`}
        src={props.src}
        /> : <Icon fill="#27a0ff" style={{width: "58px", height: "58px"}} icon="avatar" />
      }
    </Container>
  </Container>
);
