import React, { useState } from "react";
import {
  Accordeon,
  FilePdfOutlined,
  Container,
  Icon,
  DownOutlined,
  UpOutlined,
  Popup,
  FilePdfFilled,
} from "../../../../..";

import { Table } from "antd";

import { Button } from "antd";

import css from "../style.module.scss";

const { Column } = Table;

export default (props) => {
  const [benefitsVisible, setBenefitsVisible] = useState(false);
  const [brochureVisible, setBrochureVisible] = useState(false);
  return (
    <Container className={css.detailsMainContainer}>
      {benefitsVisible ? (
        <Button
          className={css.bnButton}
          type="primary"
          onClick={() => {
            setBenefitsVisible(!benefitsVisible);
          }}
        >
          {" "}
          Show Less{" "}
        </Button>
      ) : (
        <Button
          className={css.bnButton}
          type="primary"
          onClick={() => {
            setBenefitsVisible(!benefitsVisible);
          }}
        >
          {" "}
          <Icon
            style={{ width: "18px", height: "18px" }}
            icon="corn-check"
            fill="#fff"
          />{" "}
          More Details{" "}
        </Button>
      )}

      {benefitsVisible && (
        <>
          <Container className={css.section}>
            Plan value: ${props.plan.plan_only_premium.value}
          </Container>
          {props.plan.fees && props.plan.fees.length > 0 && (
            <Container className={css.section}>
              <Container className={css.section}>Fees</Container>
              <Container>
                {props.plan.fees.map((f) => {
                  return (
                    <Container className={css.fee}>
                      <Container className={css.feeLabel}>
                        {f.label}:{" "}
                      </Container>
                      <Container className={css.feeValue}>
                        ${f.value.toFixed(2)}{" "}
                      </Container>
                    </Container>
                  );
                })}
              </Container>
            </Container>
          )}

          {props.plan.available_terms && props.plan.available_terms.value && (
            <Container className={css.section}>
              Available terms: <span>{props.plan.available_terms.value}</span>
            </Container>
          )}
          {props.plan.available_terms && props.plan.available_terms.value && (
            <Container className={css.section}>
              Term length:{" "}
              <span style={{ color: "#77C3FF" }}>
                {props.plan.selected_term.value}
              </span>
            </Container>
          )}
          {false && props.plan.benefits && props.plan.benefits.length > 0 && (
            <Table
              onHeaderRow={() => {
                return {
                  className: css.header,
                };
              }}
              onRow={(record, index) => {
                return {
                  id: record.name,
                };
              }}
              rowClassName={css.benefitsRow}
              dataSource={props.benefits.map((b) => ({ ...b, key: b.name }))}
              pagination={false}
              expandIconColumnIndex={4}
              expandRowByClick={true}
              expandIcon={({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined
                    className={css.chevronUp}
                    onClick={(e) => {
                      onExpand(record, null);
                    }}
                  />
                ) : (
                  <DownOutlined
                    className={css.chevronDown}
                    onClick={(e) => {
                      onExpand(record, null);
                    }}
                  />
                )
              }
              expandable={
                false && {
                  expandedRowRender: (record) => (
                    <Container className={css.expandedBenefit}>
                      <Container className={css.expandedBenefitBackground} />
                      <Container className={css.expandedBenefitRow}>
                        <Container className={css.expandedBenefitLabel}>
                          Description
                        </Container>
                        <Container className={css.expandedBenefitDescription}>
                          {record.description}
                        </Container>
                      </Container>
                    </Container>
                  ),
                }
              }
            >
              <Column
                width="55%"
                className={css.planNameColumn}
                dataIndex="name"
                key="name"
                title={() => (
                  <Container className={css.benefitsHeadr}></Container>
                )}
                render={(text, record) => (
                  <>
                    <Container className={css.planNameContainer}>
                      {!props.pdf && (
                        <Container className={css.planNameIcon}>
                          <svg className={css.cornCheck}>
                            <use xlinkHref="/sprite.svg#corn-check-icon" />
                          </svg>
                        </Container>
                      )}
                      <Container className={css.planWithDetails}>
                        <Container className={css.planDetailsName}>
                          {text}{" "}
                        </Container>{" "}
                        <Container className={css.planDetails}>
                          {record.details}
                        </Container>
                      </Container>
                    </Container>
                  </>
                )}
              />
            </Table>
          )}
          {props.plan.brochure_link?.value && (
            <Container className={css.planBrochure}>
              <Button
                onClick={() => {
                  setBrochureVisible(true);
                }}
                danger
              >
                <FilePdfFilled />
                Plan's Brochure
              </Button>
            </Container>
          )}
        </>
      )}
      {props.plan.brochure_link && (
        <Popup
          title={"Plan Brochure"}
          className={css.popupContainer}
          visible={brochureVisible}
          onClose={() => {
            setBrochureVisible(false);
          }}
        >
          <iframe
            style={{ width: "100%" }}
            src={props.plan.brochure_link.value}
            // src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${props.plan.brochure_link.value}#toolbar=0&scrollbar=0`}
          />
        </Popup>
      )}
    </Container>
  );
};
