import React from "react";

import "./style.scss";

export * from "@ant-design/icons";
export default ({
  className,
  icon,
  fill,
  style = { maxWidth: "100%", maxHeight: "100%" },
  raw
}) => (
  
  raw ? <img className={ className  } style={style} src={"/" + icon + ".svg"}></img>
    :
  <svg style={style} className={className}>

      
    <use style={{ fill }} xlinkHref={"/sprite.svg#" + icon + "-icon"}></use>
    
  </svg>
);
