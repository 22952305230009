import React, { useState } from "react";
import {
  Accordeon,
  FilePdfOutlined,
  Container,
  Icon,
  DownOutlined,
  UpOutlined,
  Popup,
  FilePdfFilled,
} from "../../../../..";

import { Table } from "antd";

import { Button } from "antd";

import css from "../style.module.scss";

const { Column } = Table;

export default (props) => {
  const [brochureVisible, setBrochureVisible] = useState(false);
  return (
    <Container className={css.detailsMainContainer}>
      <Container className={css.planBrochure}>
        <Button
          onClick={() => {
            setBrochureVisible(true);
          }}
          danger
        >
          <FilePdfFilled />
          Plan's Brochure
        </Button>
      </Container>

      <Popup
        title={"Plan Brochure"}
        className={css.popupContainer}
        visible={brochureVisible}
        onClose={() => {
          setBrochureVisible(false);
        }}
      >
        <iframe style={{ width: "100%" }} src={props.brochure_link || "/aspire_brochure.pdf"} />
      </Popup>
    </Container>
  );
};
