import React from "react"

import css from "./style.module.scss"
import { Button, Container, Icon } from "../../.."

import { formatCurrency, getIconPropsForPlan } from "../../../../utils";

import { connect } from "react-redux"

const MiniPlanCardRaw = ({ plan, specialRates}) => {

return (
    <Container className={css.cardContainer}>
        <Container className={css.header}>
            <Container className={css.name}>{plan.plan_name.value}</Container>
            <Container className={css.carrier}>{plan.carrier.value}</Container>
        </Container>

        <Container className={css.content}>
            <Container className={css.icon}><Icon {...getIconPropsForPlan(plan)} /></Container>
            <Container className={css.values}>
                <Container className={`${css.labelValue} ${plan.deductible && plan.deductible.value ? "" : css.singleLabelValue}`}>
                    <Container className={`${css.label} ${plan.carrier.value == "Universal" ? css.smallerLabel : ""}`}>
                        {plan.premium.label}
                    </Container>
                    <Container className={`${css.value} ${plan.carrier.value == "Universal" ? css.smallerValue : ""}` } >
                        {  (plan.carrier.value !== "National General" || !specialRates) && formatCurrency(plan.premium.value)}
                        {
                            specialRates && plan.carrier.value == "National General" && formatCurrency(specialRates.priorCoverage && !specialRates.prefferedRate ? plan.premium_options.options[1].premium : specialRates.priorCoverage && specialRates.prefferedRate ? plan.premium_options.options[2].premium : plan.premium_options.options[0].premium )
                        }
                    </Container>
                </Container>
                {plan.deductible && plan.deductible.value && <Container className={css.labelValue} >
                    <Container className={css.label}>
                        {plan.deductible.label}
                    </Container>
                    <Container className={css.value} >
                        { formatCurrency(plan.deductible.value)}
                    </Container>
                </Container>}


            </Container>

        </Container>
    </Container>
);
}


const mapStateToProps = ({ specialRates} ) => {

    return {
        specialRates
    }
}

export default connect(mapStateToProps)(MiniPlanCardRaw)