import React, { useState } from "react";
import { Slider } from "antd";

import { Container, Icon, TextInput } from "../..";
import { handleInputChange } from "../../../utils";
import css from "../conversion-slider/style.module.scss";
export default ({
  min = 0,
  max = 100,
  precision = 1,
  value,
  setValue,
  icon,
  label,
  unit,
}) => {
  return (
    <Container className={css.conversionSliderContainer}>
      <Container className={css.iconTitleAndValue}>
        <Container className={css.iconAndTitle}>
          <Container className={css.icon}>
            <Icon icon={icon} />
          </Container>
          <Container>{label}</Container>
        </Container>
        <Container
          className={css.input}
          style={{ display: "flex", width: "120px" }}
        >
          <TextInput
            type="number"
            onChange={(v) => {
              setValue(handleInputChange(v));
            }}
            placeholder={unit}
            value={value == 0 ? undefined : value}
            style={{ boxShadow: "unset", height: "36px" }}
            focusedlabel={unit}
            notenrollment
          />
        </Container>
      </Container>

      <Slider
        tooltipVisible={false}
        tipFormatter={(value) => {
          return `${value.toFixed(precision)} ${value.toFixed(
            precision
          )} ${unit}`;
        }}
        min={min}
        max={max}
        onChange={(v) => setValue(Number(v))}
        value={value}
      />
      <Container className={css.marks}>
        <Container className={css.Marks}>
          <Container className={css.minMark}>
            <Container className={css.markValue}>{min.toFixed(0)} </Container>
            <Container className={css.markUnit}>{unit}</Container>
          </Container>
        </Container>
        <Container className={css.maxMarks}>
          <Container className={css.maxMark}>
            <Container className={css.markValue}>{max.toFixed(0)} </Container>
            <Container className={css.markUnit}>{unit}</Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
