import React from "react";
import { Container, Icon } from "../";
import css from "./style.module.scss";

export default (props) => (
  <Container
    style={props.style}
    className={`${css.badgeContainer} ${props.className}`}
  >
    <Icon mask={props.mask} icon={props.icon} fill={props.color} />
  </Container>
);
