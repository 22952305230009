import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import axios from "axios";
import css from "./style.module.scss";
import { Badge, Button as AntdButton, Alert, Tabs, Result, Steps, Button } from "antd";
import { Container, TextInput, QuotesForm, Icon, Select } from "../";
import { FilePdfOutlined, FilterOutlined } from "../";
import { OfferCardList, QuotesPanelProfile, SearchFilters } from "./components";

import datasourcesmock from "./temp/datasources";

import { exportPDF } from "./pdf-util";
import { CheckOutlined, FormOutlined } from "../icon";
import { connect } from "react-redux";

import ContactForm from "./components/contact-form";

import { navigate } from "gatsby";

import Lottie from "react-lottie-player";
import lottieJson from "./../../lotties/healthsherpa-no-results.json";

import states from "../../utils/data/states";
import ApplicantsMiniSection from "./components/applicants-mini-section"
import CompareMiniSection from "./components/compare-mini-section"
import CartMiniSection from "./components/cart-mini-section"

import Toastify from "toastify-js";

const unavailableStatesForMarketplace = [
  "CA",
  "CO",
  "CT",
  "DC",
  "ID",
  "MD",
  "MA",
  "MN",
  "NV",
  "NJ",
  "NY",
  "PA",
  "RI",
  "VT",
  "WA",
];

const unavailableStatesForUniversal = [
  "WA",
  "NH",
  "PA",
  "MD",
  "IL",
  "ME",
  "HI",
  "AK",
  "VT"
];

const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

const { TabPane } = Tabs;

const { Step } = Steps;

const QuotesPanelRaw = (props) => {
  const siteData = props.data;

  const steps = [];
  const stepsB = [
    {
      title: "Profile",
    },
    {
      title: "Cart",
    },
    {
      title:
        siteData?.site?.siteMetadata?.agent?.name == "Apollo"
          ? "Checkout"
          : "Finish",
    },
  ];

  try {
    (function (history: any) {
      var pushState = history.pushState;
      history.pushState = function (state) {
        if (typeof history.onpushstate == "function") {
          history.onpushstate({ state: state });
        }

        return pushState.apply(history, arguments);
      };
    })(window.history);
  } catch (e) {}

  let getParams: any = () => {
    try {
      return document.location.search
        .substring(1)
        .split("&")
        .reduce((old, current) => {
          const [key, value] = current.split("=");
          value && (old[key] = value.replace("%20", " "));
          return old;
        }, {});
    } catch (e) {
      return {};
    }
  };

  const agent = {
    id: props.data.site.siteMetadata.agent.id,
    name: props.data.site.siteMetadata.agent.name,
    title: props.data.site.siteMetadata.agent.title,
    email: props.data.site.siteMetadata.agent.email,
    website: props.data.site.siteMetadata.agent.website,
    phone: props.data.site.siteMetadata.agent.phone,
    image: props.data.site.siteMetadata.agent.assets.image,
  };

  const [results, setResults]: any[] = useState();
  const [mobileActiveContainer, setMobileActiveContainer] = useState("results");
  const [activeTab, setActiveTab] = useState(`Marketplace`);

  const [state, setNewState] = useState({
    error: false,
    lastParams: null,
    loading: true,
    lastResults: null,
    params: getParams(),
  });
  const [error, setError] = useState(false);
  const [datasources] = useState(datasourcesmock);
  const [currentState, setCurrentState] = useState(1);
  const [selectedItems, updateSelectedItems] = useState(new Set());

  const [name, setName] = useState();

  const [email, setEmail] = useState();

  const [phone, setPhone] = useState();
  const [questions, setQuestions] = useState({});
  const [selectedAgent, setSelectedAgent] = useState();
  let openRecommendations;

  const applyFilters = (items, carrier) => {
    const carrierFilters = props.filters[carrier];
    let filtered = items;

    if (carrierFilters) {
      Object.keys(carrierFilters).forEach((k) => {
        if (k == "CHECKBOX") {
          const attributes = Object.keys(carrierFilters[k]);
        
          attributes.forEach((a) => {
            const attributeValue = carrierFilters[k][a];

            if (!attributeValue || attributeValue.size == 0) {
              return;
            }

            const planMap = a.split(".");
            filtered = filtered.filter((planValue) => {
              planMap.forEach((planKey) => {
                planValue = planValue[planKey];
              });

              return Array.from(attributeValue).includes(planValue);
            });
          });
        }
      });
    }

    const generalFilters = props.filters["ALL"];

    if (generalFilters) {
      Object.keys(generalFilters).forEach((k) => {
        if (k == "MIN_MAX_SLIDER") {
          const attributes = Object.keys(generalFilters[k]);

          attributes.forEach((a) => {
            let minMax = generalFilters[k][a];

            if (minMax) {
              const planMap = a.split(".");

              filtered = filtered.filter((planValue) => {
                planMap.forEach((planKey) => {
                  planValue = planValue ? planValue[planKey] : null;
                });

                return planValue >= minMax.min && planValue <= minMax.max;
              });
            }
          });
        }
      });
    }

    const { by: sortBy, order } = props.sort;

    if (sortBy === "Premium") {
      filtered = filtered.sort((a, b) =>
        a.premium?.value >= b.premium?.value ? 1 : -1
      );
    }

    if (sortBy === "Deductible") {
      filtered = filtered.sort((a, b) =>
        a.deductible?.value >= b.deductible?.value ? 1 : -1
      );
    }

    if (order == "desc") {
      filtered = filtered.reverse();
    }

    if (filtered.some((f) => f.info && f.info.value == "STM")) {
      filtered = filtered.sort((a) =>
        a.info && a.info.value == "STM" ? -1 : 1
      );
    }

    return filtered;
  };

  const redirectToHealthSherpaByState = (health_sherpa_agent_key) => {
    try {
      const params = getParams();
      const spouse = params.spouse
        ? JSON.parse(decodeURIComponent(params.spouse))
        : null;
      const dependents = params.dependents
        ? JSON.parse(decodeURIComponent(params.dependents))
        : [];

      const zip_code = params.zip_code;
      const fips_code = params.fips_code;
      const s = params.state;
      const subsidy_eligible = params.subsidy_eligible;
      const iframe = document.createElement("iframe");
      iframe.src =
        "https://cigna.healthsherpa.com/?_agent_id=" + "scott-eckley-e6oqzw";
      iframe.width = "0px";
      iframe.height = "0px";
      iframe.style.position = "fixed";
      document.body.appendChild(iframe);

      const spouseStr = spouse
        ? `&applicants[][relationship]=spouse&applicants[][expanded]=true` +
          `&applicants[][age]=${spouse.age}` +
          `&applicants[][smoker]=${spouse.uses_tobacco}` +
          `&applicants[][gender]=${spouse.gender.toLowerCase()}`
        : "";

      const dependentsStr = dependents
        .map((d) => {
          return (
            `&applicants[][relationship]=dependent&applicants[][expanded]=true` +
            `&applicants[][age]=${d.age}` +
            `&applicants[][smoker]=${d.uses_tobacco}` +
            `&applicants[][gender]=${d.gender.toLowerCase()}`
          );
        })
        .join("&");

      setTimeout(() => {
        window.open(
          `https://www.healthsherpa.com/marketplace/recommended?` +
            `agent_id=${health_sherpa_agent_key}&zip_code=${zip_code}` +
            `&fip_code=${fips_code}` +
            `&state=${s}` +
            `&dependents_count=0` +
            `&household_size=${
              params.household_size || 1 + (spouse ? 1 : 0) + dependents.length
            }` +
            `&apply_for_subsidy=${true}` +
            `&year=2021` +
            `&all_benefits=true` +
            `&add_attributes=true` +
            `&shopping_scenario=finding_plan` +
            `&v2=true` +
            `&health_params[sort]=premium_asc` +
            `&health_params[premium]=` +
            `&health_params[deductible]=` +
            `&health_params[hsa]=false` +
            `&show_dental=true` +
            `&applicants[][age]=${params.age}` +
            `&applicants[][smoker]=${params.uses_tobacco}` +
            `&applicants[][relationship]=primary` +
            `&applicants[][gender]=${params.gender.toLowerCase()}` +
            spouseStr +
            dependentsStr +
            `&household_income=${params.income}` +
            `&csr_type=none` +
            `&premium=` +
            `&deductible=#`,
          +`&_agent_id=${health_sherpa_agent_key}` + "_blank"
        );
      }, 300);
    } catch (ex) {}
  };

  const redirectToHealthSherpa = (plan, agentId) => {
    try {
      // const zip_code = 64086;
      // const fips_code = 29095;
      // const s = "MO"

      const params = getParams();
      const spouse = params.spouse
        ? JSON.parse(decodeURIComponent(params.spouse))
        : null;
      const dependents = params.dependents
        ? JSON.parse(decodeURIComponent(params.dependents))
        : [];

      const zip_code = params.zip_code;
      const fips_code = params.fips_code;
      const s = params.state;
      const subsidy_eligible = params.subsidy_eligible;
      const iframe = document.createElement("iframe");
      iframe.src =
        "https://cigna.healthsherpa.com/?_agent_id=" +
        (agentId ? agentId : plan.health_sherpa_agent_key.value);
      iframe.width = "0px";
      iframe.height = "0px";
      iframe.style.position = "fixed";
      document.body.appendChild(iframe);

      const spouseStr = spouse
        ? `&applicants[][relationship]=spouse&applicants[][expanded]=true` +
          `&applicants[][age]=${spouse.age}` +
          `&applicants[][smoker]=${spouse.uses_tobacco}` +
          `&applicants[][gender]=${spouse.gender.toLowerCase()}`
        : "";

      const dependentsStr = dependents
        .map((d) => {
          return (
            `&applicants[][relationship]=dependent&applicants[][expanded]=true` +
            `&applicants[][age]=${d.age}` +
            `&applicants[][smoker]=${d.uses_tobacco}` +
            `&applicants[][gender]=${d.gender.toLowerCase()}`
          );
        })
        .join("&");

      setTimeout(() => {
        window.open(
          `https://www.healthsherpa.com/marketplace/plan/${plan.plan_id.value}?` +
          `agent_id=${agentId ? agentId : plan.health_sherpa_agent_key.value}&zip_code=${zip_code}` +
            `&fip_code=${fips_code}` +
            `&state=${s}` +
            `&dependents_count=0` +
            `&household_size=${
              params.household_size || 1 + (spouse ? 1 : 0) + dependents.length
            }` +
            `&apply_for_subsidy=${true}` +
            `&subsidy=${plan.subsidy.value}` +
            `&health_subsidy_used=${plan.subsidy.value} ` +
            `&year=2021` +
            `&all_benefits=true` +
            `&add_attributes=true` +
            `&shopping_scenario=finding_plan` +
            `&v2=true` +
            `&health_params[sort]=premium_asc` +
            `&health_params[premium]=` +
            `&health_params[deductible]=` +
            `&health_params[hsa]=false` +
            `&show_dental=true` +
            `&applicants[][age]=${params.age}` +
            `&applicants[][smoker]=${params.uses_tobacco}` +
            `&applicants[][relationship]=primary` +
            `&applicants[][gender]=${params.gender.toLowerCase()}` +
            spouseStr +
            dependentsStr +
            `&household_income=${params.income}` +
            `&csr_type=none` +
            `&premium=` +
            `&deductible=#`,
          +`&_agent_id=${agentId ? agentId : plan.health_sherpa_agent_key.value}` + "_blank"
        );
      }, 500);
    } catch (ex) {}
  };

  const onOffercardSelected = (value) => {
    const newSelectedItems = new Set([...selectedItems]);
    value.checked
      ? newSelectedItems.add(value.id)
      : newSelectedItems.delete(value.id);
    updateSelectedItems(newSelectedItems);
  };

  const fetchQuotes = async (attributes) => {
    let r = [];

    const propKeys = Object.keys(attributes).filter((f) =>
      datasources[1].inputs.map((m) => m.name).includes(f)
    );
    let data: any = {};

    propKeys.map((pk) => {
      const type = datasources[1].inputs.filter((f) => f.name == pk)[0];
      data[pk] =
        type.type === "number" ? Number(attributes[pk]) : attributes[pk];
      data[pk] =
        type.type === "object" || type.type === "array"
          ? JSON.parse(decodeURI(attributes[pk]))
          : attributes[pk];
    });

    data.email = "test@apollo-insurance.com";
    data.subsidy_eligible = undefined;

    try {


      const response = await axios({
        method: "post",
        url: `${props.baseUrl}/v1/plans/`,
        data,

        headers: {
          "X-Apollo-Quotes-Source": props.data.site.siteMetadata.agent.id,
        },
      });

      const mapPlans = {};
      const MARKETPLACE = response.data.plans.MARKETPLACE
        ? response.data.plans.MARKETPLACE.map((r) => {
            return {
              ...r,
              benefits: r.benefits.sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              }),
              id: uuid(),
            };
          })
        : [];

      const NATIONAL_GENERAL = response.data.plans.NATIONAL_GENERAL
        ? response.data.plans.NATIONAL_GENERAL?.map((r) => {
            return {
              ...r,
              carrier: { value: "National General" },
              // deductible:
              //   r.deductible == null
              //     ? {
              //         label: "",
              //         value: "",
              //       }
              //     : r.deductible,
              id: uuid(),
            };
          })
        : [];

      const ONE_SHARE = response.data.plans.ONE_SHARE
        ? response.data.plans.ONE_SHARE?.map((r) => {
            return { ...r, id: uuid() };
          })
        : [];

      const ASPIRE_DENTAL = response.data.plans.ASPIRE_DENTAL
        ? response.data.plans.ASPIRE_DENTAL?.map((r) => {
            return { ...r, id: uuid() };
          })
        : [];

      const UNITED_HEALTHCARE = response.data.plans.UNITED_HEALTHCARE
        ? response.data.plans.UNITED_HEALTHCARE?.map((r) => {
            return { ...r, id: uuid() };
          })
        : [];

      const UNIVERSAL = response.data.plans.UNIVERSAL
        ? response.data.plans.UNIVERSAL?.map((r) => {
          return { ...r, id: uuid() };
        })
        : [];

      response.data.plans = {
        MARKETPLACE,
        NATIONAL_GENERAL,
        ASPIRE_DENTAL,
        UNIVERSAL,
        ALL: [
          ...MARKETPLACE,
          ...NATIONAL_GENERAL,
          ...ONE_SHARE,
          ...ASPIRE_DENTAL,
          ...UNITED_HEALTHCARE,
          ...UNIVERSAL,
        ],
        MEDICAL: [
          ...MARKETPLACE,
          ...NATIONAL_GENERAL,
          ...ONE_SHARE,
          ...UNITED_HEALTHCARE,
        ],
        DENTAL: [...ASPIRE_DENTAL],
      };

      // response.data.facets[2].values[0].values = response.data.facets[2].values[0].values.map(
      //   (m) => ({
      //     ...m,
      //     badge: {
      //       color: "#F7971C",
      //       icon:
      //         m.label == "NHICSupplemental"
      //           ? "shield-filled"
      //           : m.label == "STM"
      //           ? "shield-outlined"
      //           : "association",
      //     },
      //   })
      // );

      // response.data.facets[3].values[0].values = response.data.facets[3].values[0].values.map(
      //   (m) => ({
      //     ...m,
      //     badge: {
      //       color: "#1B75BC",
      //       icon:
      //         m.label == "Basic"
      //           ? "s-outlined"
      //           : m.label == "Crown"
      //           ? "crown"
      //           : "s-filled",
      //     },
      //   })
      // );

      // response.data.facets[4].values[0].values = response.data.facets[4].values[0].values.map(
      //   (m) => ({
      //     ...m,
      //     badge:
      //       m.label == "Accident"
      //         ? {
      //             color: "#F7971C",
      //             icon: "accident",
      //           }
      //         : m.label == "Dental Insurance"
      //         ? {
      //             color: "#053555",
      //             icon: "dental-insurance",
      //           }
      //         : m.label == "HealthiestYou"
      //         ? {
      //             color: "#77C3FF",
      //             icon: "healthiest-you",
      //           }
      //         : m.label == "Hospital Indemnity"
      //         ? {
      //             color: "#FF3D3D",
      //             icon: "hospital-indemnity",
      //           }
      //         : m.label == "Short Term Health Insurance"
      //         ? {
      //             color: "#575988",
      //             icon: "short-term",
      //             mask: "url(#ra)",
      //           }
      //         : m.label == "Term Life"
      //         ? {
      //             color: "#2089E9",
      //             icon: "term-life",
      //           }
      //         : m.label == "Supplemental Indemnity"
      //         ? {
      //             color: "#F3BF38",
      //             icon: "supplemental",
      //           }
      //         : m.label == "Vision"
      //         ? {
      //             color: "#1C4C99",
      //             icon: "vision",
      //           }
      //         : m.label == "Critical Illness"
      //         ? {
      //             color: "#77C3FF",
      //             icon: "critical-illness",
      //           }
      //         : {},
      //   })
      // );

      r.push({ ...datasources[0], response });



      return r;
    } catch (error) {
      console.log("error", error);
      setError(true);
      return { error: true };
    }
  };

  try {
    window.onpopstate = (window as any).onpushstate = (e) => {
      setResults();
      setError(false);

      const params = getParams();
      setNewState({
        params,
        loading: true,
        error: false,
        lastParams: state.lastParams,
        lastResults: results,
      });
    };
  } catch (e) {}

  useEffect(() => {
       setSelectedAgent(localStorage.getItem("agent"))
    const params = document.location.search
      .substring(1)
      .split("&")
      .reduce((old, current) => {
        const [key, value] = current.split("=");
        value && (old[key] = value.replace("%20", " "));
        return old;
      }, {});

    !results &&
      fetchQuotes(params).then((r: any) => {
        if (r && r.error) {
          return;
        }
        if (!_.isEqual(r, results)) {
          setResults(r[0].response.data);
        }
      });
  }, [state, props.filters]);

  const sendQuote = async (name, email, phone) => {
    const data = {
      user_name: name,
      user_email: email,
      user_phone: phone,
      questions,
    };

    exportPDF(
      results.plans["ALL"].filter((f) => selectedItems.has(f.id)),
      agent,
      data,
      results.uuid
    );

    setCurrentState(3);
  };

  const startEnrollment = async (firstName, lastName, email, phone) => {
    setCurrentState(5);
  };

  const universalEnrollment = async ({ firstName, lastName, email, phone, agentFirstName, agentLastName, agentPhone, agentEmail, brokerId}) => {
    const params: any = document.location.search
      .substring(1)
      .split("%7B")
      .join("{")
      .split("%7D")
      .join("}")
      .split("%22")
      .join('"')
      .split("&")
      .reduce((old, current) => {
        const [key, value] = current.split("=");
        value && (old[key] = value.replace("%20", " "));
        return old;
      }, {});

  
    const plans = JSON.stringify(
      results.plans["UNIVERSAL"].filter((f) =>
        selectedItems.has(f.id)
      )
    );

    
    localStorage.setItem("universal_plans", plans);
    localStorage.setItem(
      "universal_params",
      JSON.stringify({
        ...params,
        dependents: params.dependents ? JSON.stringify(params.dependents) : [],
        spouse: params.spouse ? JSON.stringify(params.spouse) : undefined,
        email,
        firstName,
        lastName,
        phone,
        quote_id: results.id,
        universal_plans : plans,
        agentFirstName,
        agentLastName,
        agentPhone,
        agentEmail,
        brokerId
      })
    );

    window.open("/enrollment/universal", "_blank");
  };


  const finishEnrollment = async (firstName, lastName, email, phone, npn) => {
    const params: any = document.location.search
      .substring(1)
      .split("%7B")
      .join("{")
      .split("%7D")
      .join("}")
      .split("%22")
      .join('"')
      .split("&")
      .reduce((old, current) => {
        const [key, value] = current.split("=");
        value && (old[key] = value.replace("%20", " "));
        return old;
      }, {});

    const data = {
      firstName: firstName || "t ",
      lastName: lastName || "t ",
      email: email || "t ",
      homePhone: phone || "t",
      gender: params.gender || "t ",
      zip: params.zip_code || "t ",
      custom_Income: params.income || "t ",
      birthDate: params.age || "t ",
      "custom_Child 1 Date of Birth": "t ",
      "custom_Child 2 Date of Birth": "t ",
      "custom_Child 3 Date of Birth": "t ",
      "custom_Child 4 Date of Birth": "t ",
      "custom_Spouse Gender": "t ",
      "custom_Spouse Date of Birth": "t ",
    };

    const bodyFormData = new FormData();

    const plans = JSON.stringify(
      results.plans["NATIONAL_GENERAL"].filter((f) =>
        selectedItems.has(f.id)
      )
    );

    const optionalAssociationFeeEnabled = results.plans["NATIONAL_GENERAL"].filter((f) =>
      selectedItems.has(f.id)
    ).filter(f => f?.fees?.MONTHLY?.fees_details[0]?.optional == true).length > 0 ? !!props.associationFee : undefined

    Object.keys(data).forEach((k) => {
      bodyFormData.append(k, data[k]);
    });
    localStorage.setItem("plans", JSON.stringify([]));
    localStorage.setItem(
      "params",
      JSON.stringify({
        ...params,
        specialRates: props.specialRates,
        dependents: params.dependents ? JSON.stringify(params.dependents) : [],
        spouse: params.spouse ? JSON.stringify(params.spouse) : undefined,
        email,
        firstName,
        lastName,
        phone,
        quote_id: results.id,
        optionalAssociationFeeEnabled: undefined,
        plan_ids: JSON.stringify(
          results.plans["NATIONAL_GENERAL"]
            .filter((f) => selectedItems.has(f.id))
            .map((p) => p.plan_id.value)
        ),
        plans,
        plan_keys: JSON.stringify(
          results.plans["NATIONAL_GENERAL"]
            .filter((f) => selectedItems.has(f.id))
            .map((p) => p.plan_id.value)
        ),
        npn,
      })
    );

    window.open("/enrollment/national-general", "_blank");
  };

  // axios({
  //     method: "post",
  //     url: "https://app.agencybloc.com/fp/webToLead/v1/3SS413QSIKUGZIGWFPA0/",
  //     data: bodyFormData,
  //     headers: {
  //         "Content-Type": "multipart/form-data",
  //         "Access-Control-Allow-Origin": "*",
  //      },
  //     })
  //     .then(function (response) {
  //         //handle success
  //         console.log("sucess",response);
  //     })
  //     .catch(function (response) {
  //         //handle error
  //         console.log("fail",response);
  //     });
  //     // setCurrentState(3);
  // }

  return (
    <>
      <Container className={`${css.mobileMenuContainer}`}>
        <Container className={css.mobileMenu}>

          <Container onClick={() => { setMobileActiveContainer("results") }} ><Icon fill={mobileActiveContainer == "results" ? "#008AF4" : "#fff"} icon="results" /></Container>
          <Container onClick={() => { setMobileActiveContainer("applicants") }} ><Icon fill={mobileActiveContainer == "applicants" ? "#008AF4" : "#fff"}icon="applicants" /></Container>
          <Container onClick={() => { setMobileActiveContainer("compare") }} ><Icon fill={mobileActiveContainer == "compare" ? "#008AF4" : "#fff"}icon="compare-plan" /></Container>
          <Container onClick={() => { setMobileActiveContainer("cart") }} ><Icon fill={mobileActiveContainer == "cart" ? "#008AF4" : "#fff"} icon="shopping-cart-circle" /></Container>


        </Container>
      </Container>
      {true && selectedItems &&
        currentState == 1 &&
        (selectedItems.size > 0 || !_.isEmpty(questions)) && (
          <Container
            onPress={() => {
              setCurrentState(2);
            }}
            className={css.floatButton}
          >
            <Container
              onPress={() => {
                setCurrentState(2);
              }}
              className={css.mobileMenuOption}
            >
              <svg>
                <use xlinkHref="/sprite.svg#finalize-icon" />
              </svg>
              Next
            </Container>
          </Container>
        )}
      <Container className={css.mainSearchContainer}>
        {currentState == 0 && (
          <Container className={css.profileContainer}>
            <Container className={css.quotesFormContainer}>
              <Container className={css.stepsContainer}>
                <Steps
                  size="small"
                  className={css.steps}
                  current={currentState}
                  type="navigation"
                  onChange={(e) => {
                    setCurrentState(e);
                  }}
                >
                  {steps.map((item, pos) => (
                    <Step
                      key={"step" + pos + "an"}
                      disabled={
                        (pos == 1 && !results) ||
                        (pos == 2 && selectedItems.size == 0)
                      }
                      title={item.title}
                    />
                  ))}
                </Steps>
              </Container>
              <Container className={css.quotesFormInner}>
                <QuotesForm
                  agentMetadata={siteData.site.siteMetadata.agent}
                  {...getParams()}
                  onSubmit={() => {
                    setResults();
                    setError(false);
                    setCurrentState(1);
                    updateSelectedItems(new Set());
                    const params = getParams();
                    setNewState({
                      params,
                      loading: true,
                      error: false,
                      lastParams: state.lastParams,
                      lastResults: results,
                    });
                  }}
                />
              </Container>
            </Container>
          </Container>
        )}
        {!error && currentState == 1 && (
          <Container
            className={`${
              mobileActiveContainer !== "filters" ? "hidden-on-mobile" : ""
            }`}
          >
            {results && (
              <SearchFilters activeTab={activeTab} data={results.facets} />
            )}

          </Container>
        )}

        {!error && currentState == 1 && (
          <Container
            className={`hidden-on-web ${mobileActiveContainer !== "applicants" ? "hidden-on-mobile" : ""
              }`}
          >
              <ApplicantsMiniSection  />
     
    
          </Container>
        )}

        {!error && currentState == 1 && (
          <Container
            className={`hidden-on-web  ${mobileActiveContainer !== "compare" ? "hidden-on-mobile" : ""
              }`}
          >
            {results && (
              <CompareMiniSection selectedAgent={selectedAgent}
                quote_id={results?.id} itemsForComparison={results
                ? results.plans["ALL"].filter((f) => props.itemsForComparison.has(f.id))
                : []}/>
            )}

          </Container>
        )}

        {!error && currentState == 1 && (
          <Container
            className={`hidden-on-web  ${mobileActiveContainer !== "cart" ? "hidden-on-mobile" : ""
              }`}
          >
            {results && (
              <CartMiniSection onEnroll={() => { setCurrentState(2) }} cart={results
                ? results.plans["ALL"].filter((f) => selectedItems.has(f.id))
                : []} />
            )}

          </Container>
        )}

        {currentState == 1 && (
          <Container
            className={`${css.cardListContainer} ${
              mobileActiveContainer !== "results" ? "hidden-on-mobile" : ""
            }`}
          >

            <Container className={css.stepsContainer}>
              <Steps
                size="small"
                className={css.steps}
                current={currentState}
                type="navigation"
                onChange={(e) => {
                  setCurrentState(e);
                }}
              >
                {steps.map((item, pos) => (
                  <Step
                    key={"step" + pos + "a"}
                    status={
                      pos == 1 && currentState == 1 && error
                        ? "error"
                        : undefined
                    }
                    disabled={
                      pos == 2 &&
                      selectedItems.size == 0 &&
                      _.isEmpty(questions)
                    }
                    title={item.title}
                  />
                ))}
              </Steps>
            </Container>

            {error && (
              <Container className={css.errorCardContainer}>
                <Alert
                  message="Ops, there was an error"
                  showIcon
                  description="Please try again in a second and if the error persist change the data"
                  type="error"
                  className={css.errorCard}
                />
              </Container>
            )}
            {!error && (
              <Tabs
                type="card"
                tabBarGutter={-18}
                className={css.tab}
                size="small"
                renderTabBar={(props, DefaultTabBar) => {
                  return (
                    <>
                    <Container className={`${css.tabBarContainer} hidden-on-mobile`}>
                      <DefaultTabBar {...props} />
                    </Container>
                      <Container className={`${css.carriersSelect} hidden-on-web`}>
                        <Container onClick={ () => { setMobileActiveContainer("filters") }} className={css.filterIcon} ><Icon icon="filter" /></Container>
                        <Select
                          value={activeTab}
                          size="large"
                          onChange={(e) => {
                            setActiveTab(e);
                          }}
                          options={[
                    
                            {
                              label: <Container className={css.customTab}>
                                {" "}
                                <Icon
                                  fill={activeTab == "Marketplace" ? "#27A0FF" : "#27A0FF"}
                                  className={css.customIcon}
                                  icon="marketplace"
                                />{" "}
                                {`Marketplace ${results
                                    ? unavailableStatesForMarketplace.includes(
                                      state.params.state
                                    )
                                      ? "*"
                                      : "(" +
                                      applyFilters(
                                        results.plans["MARKETPLACE"],
                                        "MARKETPLACE"
                                      ).length +
                                      ")"
                                    : ""
                                  }`}{" "}
                                  </Container>,
                              value: "Marketplace",
                            },
                         
                            {
                              label: <Container className={css.customTab}>
                                {" "}
                                <Icon
                                  fill={activeTab == "National General" ? "#27A0FF" : "#27A0FF"}
                                  className={css.customIcon}
                                  icon="natgen"
                                  raw
                                />
                                {`NatGen ${results
                                    ? "(" +
                                    applyFilters(
                                      results.plans["NATIONAL_GENERAL"],
                                      "NATIONAL_GENERAL"
                                    ).length +
                                    ")"
                                    : ""
                                  }`}
                              </Container>,
                              value: "National General",
                            },
                            {
                              label: <Container className={css.customTab}>
                                {" "}
                                <Icon
                                  fill={activeTab == "Aspire Dental" ? "#27A0FF" : "#27A0FF"}
                                  style={{
                                    height: "36px",
                                    width: "36px",
                                    marginRight: "12px",
                                  }}
                                  icon="aspire-quotes-logo"
                                  raw
                                />
                                {`Aspire Dental ${results
                                  ? "(" +
                                  applyFilters(
                                    results.plans["ASPIRE_DENTAL"],
                                    "ASPIRE_DENTAL"
                                  ).length +
                                  ")"
                                  : ""
                                  }`}{" "}
                              </Container>,
                              value: "Aspire Dental",
                            },
                            {
                              label: <Container className={css.customTab}>
                                {" "}
                                <Icon
                                  fill={activeTab == "Universal" ? "#27A0FF" : "#27A0FF"}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    marginRight: "12px",
                                  }}
                                  icon="universal"
                                  raw
                                /> {`Universal ${results
                                  ? "(" +
                                  applyFilters(
                                    results.plans["UNIVERSAL"],
                                    "UNIVERSAL"
                                  ).length +
                                  ")"
                                  : ""
                                  }`}
                              </Container>,
                              value: "Universal",
                            }
                            
                          ]}
                        />
                      </Container>
                    </>
                  );
                }}
                tabBarStyle={{
                  background: "#fff",
                  maxWidth: "100%",
                 
                }}
                onChange={setActiveTab}
                activeKey={activeTab}
                centered
              >
                <TabPane
                  tab={
                    <Container className={css.customTab}>
                      {" "}
                      <Icon
                        fill={activeTab == "Marketplace" ? "#1990ff" : "#27A0FF"}
                        className={ css.customIcon}
                        icon="marketplace"
                  
                      />{" "}
                      <Badge offset={[2, -18]} count={results ? applyFilters(
                        results.plans["MARKETPLACE"],
                        "MARKETPLACE"
                      ).length : 0 } >

                      {`Marketplace`}{" "}
                      </Badge>
                    </Container>
                  }
                  key={`Marketplace`}
                >
                  {results &&
                  unavailableStatesForMarketplace.includes(
                    state.params.state
                  ) ? (
                    <Container className={css.noResultsContainer}>
                      <Container className={css.noResultsLottie}>
                        <Lottie
                          loop
                          animationData={lottieJson}
                          play
                          style={{
                            transform: "translate(-12px,6px)",
                            width: "300px",
                            height: "300px",
                          }}
                        />
                      </Container>
                      <Container className={css.noResultsText}>
                        For On-Exchange plans in your state (
                        {states[state.params.state]})
                      </Container>
                      <Container className={css.noResultsSubText}>
                        We will search on{" "}
                        <a
                          onClick={() => {
                            redirectToHealthSherpaByState(
                              results.health_sherpa_agent_key
                            );
                          }}
                        >
                          HealthSherpa.{" "}
                        </a>
                      </Container>
                      <Container className={css.noResultsButton}>
                        <Button
                          onClick={() => {
                            redirectToHealthSherpaByState(
                              results.health_sherpa_agent_key
                            );
                          }}
                          type="primary"
                        >
                          Continue
                        </Button>
                      </Container>
                    </Container>
                  ) : (
                    <OfferCardList
                      data={siteData}
                      key={uuid()}
                      loading={!results}
                      userData={[
                        {
                          label: "age",
                          value: props.age,
                        },
                        {
                          label: "zip code",
                          value: props.zip_cod,
                        },
                      ]}
                      items={
                        results
                          ? applyFilters(
                              results.plans["MARKETPLACE"],
                              "MARKETPLACE"
                            ).map((r) => ({
                              ...r,
                              selected: selectedItems.has(r.id),
                              // onSelectChange: (v) => {
                              //   redirectToHealthSherpa(
                              //     results.plans.ALL.filter(
                              //       (f) => f.id == v.id
                              //     )[0]
                              //   );
                              // },
                              onSelectChange: (value) => {
                                if (value.checked && results.plans["MARKETPLACE"].some(s => selectedItems.has(s.id))) {
                                  Toastify({
                                    text:
                                      "Your cart is limited to a single marketplace plan",
                                    duration: 2000,
                                    newWindow: true,
                                    close: true,
                                    gravity: "bottom",
                                    position: "center",
                                    backgroundColor: "#D33F49",
                                    stopOnFocus: true,
                                    className: "notification",
                                  }).showToast();
                                  return;
                                }

                                onOffercardSelected(value)
                              },
                            }))
                          : []
                      }
                    />
                  )}
                </TabPane>
                <TabPane
                  tab={
                    <Container className={css.customTab}>
                      {" "}
                      <Icon
                        fill={activeTab == "National General" ? "#fff" : "#27A0FF"}
                        className={css.customIcon}
                        icon="natgen"
                        raw
                      />
                      <Badge offset={[2,-18]} count={results ? applyFilters(
                        results.plans["NATIONAL_GENERAL"],
                        "NATIONAL_GENERAL"
                      ).length : 0 }>
                      {`National General`}
                      </Badge>
                    </Container>
                  }
                  key={`National General`}
                >
                  {
                    <OfferCardList
                      quote_id={results?.id}
                      state={getParams().state}
                      uses_tobacco={getParams().uses_tobacco}
                      associationFee={ props.associationFee}
                      onAssociationFeeChange={ payload => props.updateAssociationFee(payload)}
                      onPreferredRateSubmit={(payload) => {
                        props.updateSpecialRates(payload);
                      }}
                      specialRates={props.specialRates}
                      data={siteData}
                      key={uuid()}
                      loading={!results}
                      userData={[
                        {
                          label: "age",
                          value: props.age,
                        },
                        {
                          label: "zip code",
                          value: props.zip_code,
                        },
                      ]}
                      items={
                        results
                          ? applyFilters(
                              results.plans["NATIONAL_GENERAL"],
                              "NATIONAL_GENERAL"
                            ).map((r) => ({
                              ...r,
                              selected: selectedItems.has(r.id),
                              onSelectChange: onOffercardSelected,
                            }))
                          : []
                      }
                    />
                  }
                </TabPane>
                <TabPane
                  tab={
                    <Container className={css.customTab}>
                      {" "}
                      <Icon
                        fill={activeTab == "Aspire Dental" ? "#fff" : "#27A0FF"}
                        style={{
                          height: "36px",
                          width: "36px",
                          marginRight: "12px",
                        }}
                        icon="aspire-quotes-logo"
                        raw
                      />
                      <Badge offset={[6, -18]} count={results ? applyFilters(
                        results.plans["ASPIRE_DENTAL"],
                        "ASPIRE_DENTAL"
                      ).length : 0 } >
                        Aspire Dental
                      </Badge>
                    </Container>
                  }
                  key={`Aspire Dental`}
                >
                  {
                    <OfferCardList
                      data={siteData}
                      key={uuid()}
                      loading={!results}
                      userData={[
                        {
                          label: "age",
                          value: props.age,
                        },
                        {
                          label: "zip code",
                          value: props.zip_code,
                        },
                      ]}
                      items={
                        results
                          ? applyFilters(
                              results.plans["ASPIRE_DENTAL"],
                              "ASPIRE_DENTAL"
                            ).map((r) => ({
                              ...r,
                              selected: selectedItems.has(r.id),
                              onSelectChange: onOffercardSelected,
                            }))
                          : []
                      }
                    />
                  }
                </TabPane>
                <TabPane
                
                  tab={<Container className={css.customTab}>
                    {" "}
                    <Icon
                      fill={activeTab == "Universal" ? "#fff" : "#27A0FF"}
                      style={{
                        height: "30px",
                        width: "30px",
                        marginRight: "12px",
                        borderTopRightRadius: "8px"
                      }}
                      icon="universal"
                      raw
                    /> 
                    <Badge offset={[14, -18]} count={results ? applyFilters(
                      results.plans["UNIVERSAL"],
                      "UNIVERSAL"
                    ).length : 0 }>
                    Universal 
                    </Badge>
                  </Container> }

                  key={`Universal`}
                >
                  {
                     results &&
                      unavailableStatesForUniversal.includes(
                        state.params.state
                      ) ? (
                      <Container className={css.noResultsContainer}>
                        <Container className={css.noResultsLottie}>
                          <Lottie
                            loop
                            animationData={lottieJson}
                            play
                            style={{
                              transform: "translate(-12px,6px)",
                              width: "300px",
                              height: "300px",
                            }}
                          />
                        </Container>
                        <Container className={css.noResultsText}>
                          Universal plans are not avaiable in your state.
                         
                        </Container>
                      </Container>
                    ) : <OfferCardList
                      data={siteData}
                      key={uuid()}
                      loading={!results}
                      userData={[
                        {
                          label: "age",
                          value: props.age,
                        },
                        {
                          label: "zip code",
                          value: props.zip_code,
                        },
                      ]}
                      items={
                        results
                          ? applyFilters(
                            results.plans["UNIVERSAL"],
                            "UNIVERSAL"
                          ).map((r) => ({
                            ...r,
                            selected: selectedItems.has(r.id),
                            onSelectChange: (value) => { 

                              if (value.checked && results.plans["UNIVERSAL"].some(s => selectedItems.has(s.id))) {
                                Toastify({
                                  text:
                                    "Your cart is limited to a single universal plan",
                                  duration: 2000,
                                  newWindow: true,
                                  close: true,
                                  gravity: "bottom",
                                  position: "center",
                                  backgroundColor: "#D33F49",
                                  stopOnFocus: true,
                                  className: "notification",
                                }).showToast();
                                return ;
                              }

                              onOffercardSelected(value)
                            },
                          }))
                          : []
                      }
                    />
                  }
                </TabPane>
                {/* <TabPane
                  tab={`One Share ${
                    results
                      ? "(" +
                        applyFilters(results.plans["ONE_SHARE"], "ONE_SHARE")
                          .length +
                        ")"
                      : ""
                  }`}
                  key={`OneShare`}
                >
                  {
                    <OfferCardList
                      data={siteData}
                      key={uuid()}
                      loading={!results}
                      userData={[
                        {
                          label: "age",
                          value: props.age,
                        },
                        {
                          label: "zip code",
                          value: props.zip_code,
                        },
                      ]}
                      items={
                        results
                          ? applyFilters(
                              results.plans["ONE_SHARE"],
                              "ONE_SHARE"
                            ).map((r) => ({
                              ...r,
                              selected: selectedItems.has(r.id),
                              onSelectChange: onOffercardSelected,
                            }))
                          : []
                      }
                    />
                  }
                </TabPane>
                <TabPane
                  tab={`UHC ${
                    results
                      ? "(" +
                        applyFilters(
                          results.plans["UNITED_HEALTHCARE"],
                          "UNITED_HEALTHCARE"
                        ).length +
                        ")"
                      : ""
                  }`}
                  key={`United Healthcare`}
                >
                  {
                    <OfferCardList
                      data={siteData}
                      key={uuid()}
                      loading={!results}
                      userData={[
                        {
                          label: "age",
                          value: props.age,
                        },
                        {
                          label: "zip code",
                          value: props.zip_code,
                        },
                      ]}
                      items={
                        results
                          ? applyFilters(
                              results.plans["UNITED_HEALTHCARE"],
                              "UNITED_HEALTHCARE"
                            ).map((r) => ({
                              ...r,
                              selected: selectedItems.has(r.id),
                              onSelectChange: onOffercardSelected,
                            }))
                          : []
                      }
                    />
                  }
                </TabPane>
 */}
              </Tabs>
            )}

          </Container>
        )}
        {siteData?.site?.siteMetadata?.agent?.name == "Apollo" &&
          currentState == 2 && (
            <Container className={css.profileContainer}>
              <Container className={css.quotesFormContainer}>
                <Container className={css.stepsContainer}>
                  <Steps
                    size="small"
                    className={css.steps}
                    current={currentState}
                    type="navigation"
                    onChange={(e) => {
                      setCurrentState(e);
                    }}
                  >
                    {steps.map((item, pos) => (
                      <Step key={"stepsz" + pos} title={item.title} />
                    ))}
                  </Steps>
                </Container>
                <ContactForm
                  selectedAgent={ selectedAgent}
                  setSelectedAgent={setSelectedAgent}
                  redirectToHealthSherpa={redirectToHealthSherpa}
                  startEnrollment={startEnrollment}
                  finishEnrollment={finishEnrollment}
                  universalEnrollment={universalEnrollment}
                  results={results}
                  selectedItems={selectedItems}
                  specialRates={props.specialRates}
                  goBack={() => {
                    setCurrentState(1);
                  }}
                />
              </Container>
            </Container>
          )}

        <QuotesPanelProfile
          selectedAgent={ selectedAgent}
          setSelectedAgent= { setSelectedAgent }
          quote_id={results?.id}
          itemsForComparison={results
            ? results.plans["ALL"].filter((f) => props.itemsForComparison.has(f.id))
            : []}
          cart={
            results
              ? results.plans["ALL"].filter((f) => selectedItems.has(f.id))
              : []
          }
          onEnroll={ () => { setCurrentState(2)} }
          openRecommendations={(f) => {
            openRecommendations = f;
          }}
          questions={questions}
          onSave={(q) => {
            setQuestions(q);
          }}
          enabled={results}
          onPicwellOn={() => {
            const tempR = { ...results };

            tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map(
              (res, index) => {
                return {
                  ...res,
                  score: (100 / tempR.plans.MARKETPLAxCE.length) * (index + 1),
                };
              }
            );
            setResults(tempR);
          }}
          onPicwellOff={() => {
            const tempR = { ...results };

            tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map(
              (res, index) => {
                return { ...res, score: null };
              }
            );

            setResults(tempR);
          }}
          data={siteData}
          mobileActiveContainer={mobileActiveContainer}
        />
      </Container>
      <Container id="pdf" />
    </>
  );
};

const mapStateToProps = ({ associationFee,baseUrl, filters, itemsForComparison, results, sort, specialRates }) => {
  return {
    baseUrl,
    associationFee,
    itemsForComparison,
    filters: { ...filters },
    results: { ...results },
    sort: { ...sort },
    specialRates,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuotes: (payload) => dispatch({ type: `FETCH_QUOTES`, payload }),
    setApplicants: (payload) =>
      dispatch({ type: `SET_APPLICANTS`, payload }),
    updateSpecialRates: (payload) =>
      dispatch({ type: `UPDATE_SPECIAL_RATES`, payload }),
    updateAssociationFee: (payload) =>
      dispatch({ type: `UPDATE_ASSOCIATION_FEE`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotesPanelRaw);

export { OfferCardList, SearchFilters };
