import React from "react";

import { Button, Container, Popup } from "../../../../../../";

import css from "./style.module.scss";

export default (props) => {
  return (
    <Popup
      title={
        <Container effects={[1]} className={`${css.picwellContainer}`}>
          Bio
        </Container>
      }
      visible={props.visible}
      onClose={props.onClose}
    >
      <Container className={css.contentContainer}>
        <div className="about-container">
          <div
            className="about-our-story-container"
            style={{ paddingTop: "72px", marginTop: "-36px" }}
          >
            <div className="about-our-story">OUR STORY</div>

            <div className="about-our-story-sections">
              <div className="about-our-story-section">
                <div className="about-our-story-section-title">
                  APOLLO IN 2010
                </div>

                <div className="about-our-story-section-text">
                  Our mission to Positively Impact People’s Lives was born - we
                  set out to serve our clients by meeting their needs in
                  assisting them to find the best possible healthcare package
                  for their budget. We knew the struggles so many face with
                  health insurance and how frustrating it can be. Our team knew
                  we had to battle for our clients and act as advocates for our
                  clients and not only learn about the industry, but master the
                  intricacies of every plan available.
                </div>
              </div>
              <div className="about-our-story-section">
                <div className="about-our-story-section-title">
                  APOLLO TODAY
                </div>

                <div className="about-our-story-section-text">
                  Since 2010, Apollo has blazed a path through the thick of this
                  volatile industry by providing our clients with custom built
                  coverage. Our clients value what we do for them, and often
                  refer us to their friends and family. To Apollo, that says our
                  clients trust us and know we truly care about their healthcare
                  needs. We didn't want to be another healthcare brokerage, we
                  wanted to show our clients we are their eyes and ears in this
                  turbulent and ever-changing industry. We pride ourselves on
                  ensuring we help keep our clients money in their pocket
                  instead of giving it to the insurance company. At Apollo, we
                  treat all of our clients just like family.
                </div>
              </div>
            </div>
          </div>
          <div className="anchor" id="leadership" />

          <div className="about-leadership-container">
            <div className="about-leadership-title">LEADERSHIP</div>
            <div className="about-leadership-subtitle">
              Meet the Team That Makes It Happen
            </div>

            <div className="about-leadership-cards">
              <div className="about-leadership-card scott">
                <div className="about-leadership-card-title">Scott Eckley</div>
                <div className="about-leadership-position">President</div>
              </div>
              <div className="about-leadership-card dillon">
                <div className="about-leadership-card-title">Dillon Roher</div>
                <div className="about-leadership-position">
                  Chief Technical Officer
                </div>
              </div>
              <div className="about-leadership-card jordan">
                <div className="about-leadership-card-title">Jordan Eckley</div>
                <div className="about-leadership-position">
                  Vice President & Co-Owner
                </div>
              </div>
              <div className="about-leadership-card luke">
                <div className="about-leadership-card-title">Luke Eckley</div>
                <div className="about-leadership-position">
                  Vice President & Co-Owner
                </div>
              </div>
            </div>
          </div>
          <div className="anchor" id="our-team" />
          <div className="about-junior-partners-container">
            <div className="about-junior-partners-title">JUNIOR PARTNERS</div>

            <div className="about-junior-partners-cards">
              <div className="about-junior-partner-card jeff">
                <div className="about-junior-partner-card-title">
                  Jeff Gueldner{" "}
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
              <div className="about-junior-partner-card rich">
                <div className="about-junior-partner-card-title">
                  Rich Robbertacio
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
              <div className="about-junior-partner-card phill">
                <div className="about-junior-partner-card-title">
                  Phil Kathol
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
              <div className="about-junior-partner-card george">
                <div className="about-junior-partner-card-title">
                  George Kopp
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
            </div>
            <div className="about-junior-partners-cards">
              <div className="about-junior-partner-card jared">
                <div className="about-junior-partner-card-title">
                  Jared Boyett
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
              <div className="about-junior-partner-card marq">
                <div className="about-junior-partner-card-title">
                  Marq Hughes
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
              <div className="about-junior-partner-card peebles">
                <div className="about-junior-partner-card-title">
                  Matt Peebles
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
              <div className="about-junior-partner-card matt">
                <div className="about-junior-partner-card-title">
                  Matt Safranek
                </div>
                <div className="about-junior-partner-card-posistion">
                  Junior Partner
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="anchor" id="about-end" />
      </Container>
    </Popup>
  );
};
