import React, { useState } from "react";

import DecisionSupport from "./decision-support";

import ApplicantsMiniSection from "../applicants-mini-section";
import CartMiniSection from "../cart-mini-section";
import CompareMiniSection from "../compare-mini-section";

import AboutPopup from "./decision-support/popups/about-popup";

import { Avatar, Badge, Button, Container, ProductCard, Icon } from "../../../";
import { FacebookFilled, ScheduleOutlined, PhoneOutlined } from "../../../";
import AgentRecommendationPopup from "../agent-recommendation-popup";

import css from "./style.module.scss";
import _ from "lodash";
import { FormOutlined } from "@ant-design/icons";

import { connect } from "react-redux";

import { Dropdown, Menu } from "antd";

import agents from "./agents"

const ProfileRaw = ({
  cart,
  data,
  itemsForComparison,
  mobileActiveContainer,
  onEnroll,
  onPicwellOn,
  onPicwellOff,
  enabled,
  onSave,
  quote_id,
  questions,
  openRecommendations,
  selectedAgent, setSelectedAgent, setSelectedAgentRedux
}) => {
  const [aboutPopupVisible, setAboutPopupVisible] = useState(false);
  const [
    agentRecommendationPopupVisible,
    setAgentRecommendationPopupVisible,
  ] = useState(false);

  const [dropDownVisible, setDropDownVisible] = useState(true);

  const openAboutPopup = () => setAboutPopupVisible(true);
  const closeAboutPopup = () => setAboutPopupVisible(false);
  const openAgentRecommendationPopup = () =>
    setAgentRecommendationPopupVisible(true);
  const closeAgentRecommendationPopup = () =>
    setAgentRecommendationPopupVisible(false);

  openRecommendations && openRecommendations(openAgentRecommendationPopup);

  return (
    <Container
      className={`${css.rightBarContainer} ${
        mobileActiveContainer !== "user-info" ? "hidden-on-mobile" : ""
      }`}
    >
      <Container className={css.avatarContainer}>
        <Avatar
          onPress={openAboutPopup}
          link=""
          round="true"
          src={data?.site?.siteMetadata?.agent?.imageSrc}
          initials={ selectedAgent ? (selectedAgent[0] + selectedAgent.split(" ")[1][0]) : undefined }
        />

        <Container className={css.agentName}>
          {data?.site?.siteMetadata?.agent?.name}
        </Container>

      </Container>
      <Container className={css.selectAgentContainer}>
        <Container className={css.selectAgent}>
          { selectedAgent ? <Container className={ css.agentToRemove} onClick={ () => {  localStorage.removeItem("agent"); setSelectedAgent() ; setSelectedAgentRedux(null) }}> { selectedAgent } X </Container>: 
          <Dropdown
            trigger={["click"]}
           
            overlay={

              
              <Menu className={ css.agentsMenu}>
                {
                  agents.map( a => {
                    return <Menu.Item onClick={ () => { 
                      localStorage.setItem("agent", a.firstName + " " + a.lastName)
                      setSelectedAgent(a.firstName + " " + a.lastName)
                      setSelectedAgentRedux(a.firstName + " " + a.lastName)
                }} style={{paddingLeft:"24px"}}  key={ "agent-" + a.firstName + a.lastName }>
                      { a.firstName + " " + a.lastName}
                    </Menu.Item>
                  })
                }
      
              </Menu>
            }
          >
            <Container className={ css.selectAgent } onClick={() => setDropDownVisible(true)}>
              Select an agent <span>▼</span>
            </Container>
          </Dropdown> }
        </Container>
      </Container>

      {
        <Container className={`${css.contactSupportContainer}`}>
          {/* <Container className={css.contactSupport}>
            <Container className={css.msg1}>Need decision support?</Container>

            <Container className={css.contactCard}>
              <Container className={css.agentInfo}>
                <svg className={css.phone}>
                  <use xlinkHref="/sprite.svg#phone-outlined-icon" />
                </svg>

                <span>{data.site.siteMetadata.agent.phone}</span>
              </Container>

              {data?.site?.siteMetadata?.agent?.calendly && (
                <a
                  target="_blank"
                  href={data?.site?.siteMetadata?.agent?.calendly}
                >
                  <Container className={css.agentInfo}>
                    <svg>
                      <use xlinkHref="/sprite.svg#schedule-call-icon" />
                    </svg>
                    <span>Schedule a Call</span>
                  </Container>
                </a>
              )}
              <a target="_blank" href={"https://m.me/ApolloInsuranceAdvisors"}>
                <Container className={css.agentInfo}>
                  <svg>
                    <use xlinkHref="/sprite.svg#facebook-icon" />
                  </svg>
                  <span>Send Message</span>
                </Container>
              </a>
            </Container>
          </Container> */}
        </Container>
      }

      <ApplicantsMiniSection />
      <CompareMiniSection
        selectedAgent={ selectedAgent}
        quote_id={quote_id}
        onCompare={() => {}}
        itemsForComparison={itemsForComparison}
      />
      <CartMiniSection onEnroll={onEnroll} cart={cart} />
      {
        <DecisionSupport
          enabled={enabled}
          onPicwellOn={onPicwellOn}
          onPicwellOff={onPicwellOff}
          onSubmit={onPicwellOn}
        />
      }
      <AboutPopup
        data={data}
        visible={aboutPopupVisible}
        onClose={closeAboutPopup}
      />
      <AgentRecommendationPopup
        {...questions}
        onSave={(q) => {
          onSave && onSave(q);
        }}
        data={data}
        visible={agentRecommendationPopupVisible}
        onClose={closeAgentRecommendationPopup}
      />
    </Container>
  );
};

const mapStateToProps = ({ selectedAgent }) => {
  return {
    selectedAgent
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAgentRedux: (payload) =>
      dispatch({ type: `SET_SELECTED_AGENT`, payload }),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ProfileRaw);
