import React, { useState, useContext, useRef } from "react";
import { Select } from "antd";
import css from "./style.module.scss";

import { Container, Icon, RightOutlined } from "../";
import { v4 as uuid } from "uuid";
import { PlatformContext } from "../../context";

export const { Option: SelectOption } = Select;

export default (props) => {
  const uuidv4 = uuid();
  const platform = useContext(PlatformContext);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  return (
    <Container
      className={`apollo-select  ${css.apolloSelect}  ${
        platform?.enrollment ? "enrollment-select" : ""
      }   ${css.relative} ${props.left ? "apollo-select-left" : ""} 
      ${platform.enrollment ? (open ? "" : "box-shadow-none") : ""}
      `}
    >
      <Select
        ref={ref}
        onDropdownVisibleChange={(o) => {
          setOpen(o);
        }}
        open={open}
        id={uuidv4}
        suffixIcon={() => <RightOutlined className={css.icon} />}
        dropdownClassName={css.dropdown}
        {...props}
        className={``}
      />
      {props.before && (
        <Container
          onPress={() => {
            setOpen(true);
            ref.current.focus();
          }}
          className={`${css.before} ${
            props.before && props.value !== undefined ? css.active : ""
          } ${platform?.enrollment ? css.enrollmentBefore : ""}`}
        >
          {props.before}
        </Container>
      )}
      {platform?.enrollment && (
        <Container className={css.chevron}>
          <Icon
            className={css.chevronDownIcon}
            fill={"#77C3FF"}
            icon="chevron-down"
          />
        </Container>
      )}
    </Container>
  );
};
