import React, { useState,useEffect } from "react";

import {
  Container,
  TextInput,
  Icon,
  RadioButton,
  ProductCard,
  OfferCard,
  Select,
  Popup,
} from "../../../";

import { Button, Collapse, AutoComplete, Input } from "antd";

import css from "./style.module.scss";
import { PropertySafetyFilled } from "@ant-design/icons";
import agents from "../profile/agents";

const { Panel } = Collapse;

export default ({
  options,
  plans,
  enrollmentKeyLabel,
  disabled,
  onEnrollmentButtonClick,
  title,
  requireId = false,
  selectedAgent,
  keys = [],
  defaultKey = null
}) => {

  let agent = selectedAgent
    ? agents.filter(
      (a) => selectedAgent === a.firstName + " " + a.lastName
    )[0]
    : {};

  let agentOption = keys.filter(o => o.name == agent.firstName + " " + agent.lastName);

  const [enrollmentKey, setEnrollmentKey] = useState(defaultKey ? defaultKey : agentOption.length ? agentOption[0].id : undefined);

  useEffect(() => {
    if(!defaultKey) {
    agent = selectedAgent
      ? agents.filter(
        (a) => selectedAgent === a.firstName + " " + a.lastName
      )[0]
      : {};

     agentOption =  keys.filter(o => o.name == agent.firstName + " " + agent.lastName);

    if(agentOption.length){
      setEnrollmentKey(agentOption[0].id)
    } else {
      setEnrollmentKey()
    }
  }

  }, [selectedAgent]);


  return (
    <Container className={css.finalFormSummary}>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "36px",
        }}
        className={css.planTypeContainer}
      >
        <Container className={css.planType}>{title}</Container>
        <Container
          style={{
            maxWidth: "480px",
            margin: "auto",
            marginTop: "36px",
            marginBottom: "36px",
          }}
        >
          <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            style={{ width: 250 }}
            options={options}
            value={enrollmentKey}
            onChange={(v) => {
              setEnrollmentKey(v);
            }}
          >
            <Input.Search
              value={enrollmentKey}
              size="large"
              placeholder={enrollmentKeyLabel}
            />
          </AutoComplete>
        </Container>
        <Collapse ghost>
          {plans.map((plan) => {
            return (
              <Panel
                showArrow={false}
                key={plan.id}
                header={<ProductCard plan={plan} />}
              >
                <Container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <OfferCard hideHeader {...plan} />
                </Container>
              </Panel>
            );
          })}
        </Collapse>

        <Container
          className={css.totalPremiumContainer}
        >
          <Container className={css.totalPremium}>Total premium</Container>
          <Container className={`${css.totalPremiumValue}`}>
            ${" "}
            {plans
              .reduce((a, b) => {
                return a + b.premium.value;
              }, 0)
              .toFixed(2)}
          </Container>
        </Container>

        <Container className={css.sendButton}>
          <Button
            disabled={disabled || (requireId ? enrollmentKey ? false : true : false)}
            onClick={() => {
              onEnrollmentButtonClick && onEnrollmentButtonClick(enrollmentKey);
            }}
            type="primary"
          >
            <Icon
              style={{ width: "36px", height: "36px" }}
              fill={disabled ? "#ccc" : "#fff"}
              icon="enrollment-cart"
            />
            Start Your Enrollment
          </Button>
        </Container>
      </Container>
    </Container>
  );
};
