import React, { useEffect, useState, useRef } from "react";

interface ContainerProps {
  className?: string;
  style?: React.CSSProperties;
  effects?: any[];
  children?: any;
  onPress?();
  onClick?();
  key?: string;
  id?: string;
}

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = React.useCallback(() => {
    setTick((tick) => tick + 0.5);
  }, []);
  return update;
}

export default (props: ContainerProps) => {
  let newProps;

  // if (props.effects) {
  //     const state = useState({ ...props });
  //     const forceUpdate = useForceUpdate();
  //     newProps = state[0];
  //     const setNewProps = state[1];
  //     newProps.className += " display-with-delay";

  //         newProps.effects.forEach(effect => {
  //             setTimeout(() => {
  //                 newProps.className = newProps.className.replace("display-with-delay", " display-with-delay-on ")
  //                 setNewProps(newProps);

  //                 forceUpdate();
  //             }, 100)
  //         })

  // } else {
  newProps = {
    ...props,
    className: props.onPress
      ? (props.className ? props.className : "") + " link"
      : props.className,
  };
  // }

  if (props.onPress) {
    newProps.onClick = props.onPress;
    delete newProps.onPress;
  }

  return <div id={newProps.id} {...newProps} className={newProps.className} />;
};
