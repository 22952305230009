import React, { useState } from "react";

import { Container, Icon } from "../../.."

import css from "./style.module.scss"

export default (props) => { 

    const [collapsed, setCollapsed] = useState(props.openByDefault ? false: true);

    return <Container className={css.panelContainer}>
        <Container className={ css.collapseIcon}>
            <Icon fill="#053454" stroke="#000" icon={collapsed ? "uncollapse" : "collapse" } />
        </Container>
        <Container onClick={() => setCollapsed(!collapsed)} className={`${css.panelHeader}  ${collapsed ? css.collapsedHeader : ""}`}>{props.title}</Container>
        <Container className={`${css.panelContent} ${collapsed ? css.collapsedContent : css.visibleContent}`}>
         { props.children}
        </Container>
    </Container>
}