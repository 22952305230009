import React, { useState } from "react";
import {
  Button,
  Container,
  Popup,
  Slider,
  DollarOutlined,
  IdcardOutlined,
  UserOutlined,
  Icon,
  DollarTwoTone,
  CheckCircleOutlined,
} from "../../../../../../";
import { OfferCard } from "../../../../../components";
import css from "./style.module.scss";
import { Steps, message } from "antd";
import { Radio } from "antd";
import { v4 as uuid } from "uuid";
import { Affix } from "antd";

const questions = [
  {
    id: 1,
    group: "profile",
    question:
      "How important is it that your current doctor(s) are in the network of the health plan that you choose?",
    options: [
      { value: "not_important", text: "Not Important" },
      { value: "not_very_important", text: "Not Very Important" },
      { value: "important", text: "Important" },
      { value: "very_important", text: "Very Important" },
    ],
    type: "radiobutton",
  },
  {
    id: 2,
    group: "profile",
    question:
      "	Think about the physician who's most important to you. For how many years have you been with this physician?",
    options: [
      { value: "lt_1", text: "One or less" },
      { value: "2_3", text: "2 to 3" },
      { value: "4_5", text: "4 to 5" },
      { value: "gt_5", text: "More than 5" },
      { value: "0", text: "I don't have a current physician" },
    ],
    type: "radiobutton",
  },
  {
    id: 10,
    group: "profile",

    question:
      "If one of the doctors you care most about is out of network, would find a new in-network doctor if it could save you money?",
    options: [
      { value: "no", text: "No" },
      { value: "yes", text: "Yes" },
    ],
    type: "radiobutton",
  },

  {
    id: 3,
    group: "profile",

    question:
      "Are you or a covered family member planning to have a child in the next year?",
    options: [
      { value: "no", text: "No" },
      { value: "yes", text: "Yes" },
    ],
    type: "radio",
  },
  {
    id: 4,
    group: "earnings",

    question:
      "What was your total household income before taxes during the past 12 months?",
    options: [
      { value: "lt_25", text: "Less than $25,000" },
      { value: "25_35", text: "$25,000 to $34,999" },
      { value: "35_50", text: "$35,000 to $49,999" },
      { value: "50_75", text: "$50,000 to $74,999" },
      { value: "75_100", text: "$75,000 to $99,999" },
      { value: "100_150", text: "$100,000 to $149,999" },
      { value: "150_200", text: "$150,000 to $199,999" },
      { value: "gt_200", text: "$200,000 or more" },
    ],
    marks: {
      0: "$0",
      10: "$25k",
      25: "$35k",
      37: "$50k",
      50: "$75k",
      62: "$100k",
      75: "$150k",
      90: "$200k or more",
    },
    type: "slider",
  },
  {
    id: 8,
    group: "earnings",

    question:
      "If you have a Health Savings Account (HSA), what is your current balance?",
    options: [
      { value: "not_applicable", text: "I don't have one" },
      { value: "1_1000", text: "$1 to $999" },
      { value: "1000_3000", text: "$1000 to $2999" },
      { value: "3000_5000", text: "$3000 to $4999" },
      { value: "gt_5000", text: "$5000 or more" },
      { value: "unknown", text: "I don't know my balance." },
      { value: "prefer_no_answer", text: "I prefer not to answer" },
    ],
    marks: {
      0: "$0",
      10: "$1k",
      50: "$3k",
      90: "$5k or more",
    },
    type: "slider",
  },
  {
    id: 9,
    group: "earnings",

    question:
      "If you needed to receive unexpected medical care, what is the largest bill that you could afford to pay?",
    options: [
      { value: "lt_500", text: "Less than $500" },
      { value: "500_1000", text: "$500 to $999" },
      { value: "1000_2000", text: "$1,000 to $1,999" },
      { value: "2000_3000", text: "$2,000 to $2,999" },
      { value: "3000_4000", text: "$3,000 to $3,999" },
      { value: "4000_6000", text: "$4,000 to $5,999" },
      { value: "gt_6000", text: "$6,000 or more" },
    ],
    marks: {
      0: "$0",
      10: "$1k",
      30: "$2k",
      50: "$3k",
      70: "$4k",
      90: "$6k or more",
    },
    type: "slider",
  },
  {
    id: 5,
    group: "chooseone",
    question:
      "Imagine that you have a 10% chance of being injured and needing surgery.",
    options: [
      { value: "plan_a", text: "Plan A" },
      { value: "plan_b", text: "Plan B" },
    ],
    type: "offercard",
  },
  {
    id: 6,
    group: "chooseone",

    question:
      "Imagine that you have a 50% chance of being injured and needing surgery.",
    options: [
      { value: "plan_a", text: "Plan A" },
      { value: "plan_b", text: "Plan B" },
    ],
    type: "offercard",
  },
];

const { Step } = Steps;

export default (props) => {
  const [current, setCurrent] = React.useState({
    profile: {
      questions: [1, 2, 3, 10],
      ready: true,
      active: true,
    },
    earnings: {
      questions: [4, 8, 9],
      ready: false,
      active: false,
    },

    chooseone: {
      questions: [5, 6],
      ready: false,
      active: false,
    },
  });

  function formatter(value) {
    return `${value}%`;
  }

  const answers = questions.reduce((old, n) => {
    old[n.id] = useState();
    return old;
  }, {});

  const stepClick = (e) => {
    const newCurrent = {
      profile: {
        questions: [1, 2, 3, 10],
        ready: current["profile"].questions.every((q) => !!answers[q][0]),
        active: e === 0,
        error:
          e > 0 && !current["profile"].questions.every((q) => !!answers[q][0]),
      },
      earnings: {
        questions: [4, 8, 9],
        ready: current["earnings"].questions.every((q) => !!answers[q][0]),
        active: e === 1,
        error:
          e > 1 && !current["profile"].questions.every((q) => !!answers[q][0]),
      },

      chooseone: {
        questions: [5, 6],
        ready: current["chooseone"].questions.every((q) => !!answers[q][0]),
        active: e === 2,
      },
    };
    setCurrent(newCurrent);
  };

  const steps = [
    {
      name: "profile",
      icon: <UserOutlined />,
      title: (
        <Container
          onPress={() => {
            stepClick(0);
          }}
          className={css.questionGroupTitle}
        >
          {" "}
          ABOUT YOU AND YOUR PLANS
        </Container>
      ),
      content: questions
        .filter((q) => q.group == "profile")
        .map((q, ind) => (
          <Container key={uuid()} className={css.questionContainer}>
            <Container className={css.questionTitle}>
              {Number(ind) + 1 + ") " + q.question}
            </Container>
            <Container>
              <Radio.Group
                value={answers[q.id][0]}
                onChange={(e) => {
                  answers[q.id][1](e.target.value);
                }}
                optionType="button"
                className={css.answersGroup}
              >
                {q.options.map((o, index) => (
                  <Radio.Button key={uuid()} value={o.value}>
                    {o.text}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Container>
          </Container>
        )),
    },
    {
      name: "earnings",
      icon: <DollarOutlined />,
      title: (
        <Container
          onPress={() => {
            stepClick(1);
          }}
          className={css.questionGroupTitle}
        >
          {" "}
          INCOME DETAILS
        </Container>
      ),
      content: questions
        .filter((q) => q.group == "earnings")
        .map((q, ind) => (
          <Container key={q.group} className={css.questionContainer}>
            <Container className={css.questionTitle}>
              {Number(ind) + 1 + ") " + q.question}
            </Container>
            <Container className={css.rangeSliderContainer}>
              <Slider
                tipFormatter={formatter}
                included={false}
                marks={q.marks}
                key={"slider" + q.id}
                value={answers[q.id][0]}
                onChange={handleSliderChange.bind(q.id)}
                className={css.rangeSlider}
              />
              {true && (
                <Icon
                  className={css.rangeIcon}
                  component={() => <DollarTwoTone />}
                />
              )}
            </Container>
          </Container>
        )),
    },
    {
      name: "chooseone",
      icon: <IdcardOutlined />,
      title: (
        <Container
          onPress={() => {
            stepClick(2);
          }}
          className={css.questionGroupTitle}
        >
          WHICH PLAN WOULD YOU CHOOSE ?
        </Container>
      ),
      content: questions
        .filter((q) => q.group == "chooseone")
        .map((q, ind) => (
          <Container key={uuid()} className={css.questionContainer}>
            <Container className={css.questionTitle}>
              {Number(ind) + 1 + ") " + q.question}
            </Container>
            <Container className={css.offerCardGroup}>
              {q.options.map((o, index) => (
                <Container
                  key={uuid()}
                  onPress={() => {
                    answers[q.id][1](o.value);
                  }}
                >
                  <OfferCard
                    selected={answers[q.id][0] == o.value}
                    innerStyle={{
                      ...(answers[q.id][0] == o.value
                        ? { borderColor: "#1890ff" }
                        : {}),
                    }}
                    style={{ margin: "12px" }}
                    key={uuid()}
                    showSelection={true}
                    carrier={{
                      value: "",
                    }}
                    plan_name={{
                      value: o.text,
                    }}
                    premium={{
                      label: "Premium",
                      value: index == 0 ? "4000" : "1000",
                    }}
                    deductible={{
                      label: "Deductible",
                      value: index == 0 ? "0" : "5000",
                    }}
                    info={{
                      value:
                        index == 0
                          ? "Annual costs are $3000 higher and you pay $0 out-of-pocket for the surgery."
                          : "Annual costs are $3000 lower, but you have to pay $5000 out-of-pocket for the surgery.",
                    }}
                    id={uuid()}
                    value={o.value}
                  >
                    {o.text}
                  </OfferCard>
                </Container>
              ))}
            </Container>
          </Container>
        )),
    },
  ];

  function handleSliderChange(v) {
    answers[this][1](v);
  }

  return (
    <>
      <Popup
        left={
          <Container className={`${css.decisionSupportPopupHeader}`}>
            <Container className="hidden-on-mobile">Decision support</Container>
          </Container>
        }
        title={
          <Container effects={[1]} className={`${css.picwellContainer}`}>
            <Container className="only-on-mobile">Decision support</Container>
            <Container className={`${css.poweredByPicwell}`}>
              powered by{" "}
              <img
                src={"/partners/picwell/picwell-green-2020.png"}
                alt="Picwell"
              ></img>
            </Container>
          </Container>
        }
        visible={props.visible}
        onClose={props.onClose}
      >
        <Container className={css.contentContainer}>
          <Steps current={100} direction="vertical">
            {steps.map((item, pos) => (
              <Step
                key={uuid()}
                status={
                  current[item.name].error
                    ? "error"
                    : current[item.name].ready
                    ? "finish"
                    : current[item.name].active
                    ? "process"
                    : "wait"
                }
                icon={
                  current[item.name].ready ? <CheckCircleOutlined /> : item.icon
                }
                description={
                  steps[Number(pos)] &&
                  current[item.name].active &&
                  steps[Number(pos)].content
                }
                title={item.title}
              />
            ))}
          </Steps>

          {
            <Container
              style={{
                marginTop: "12px",
                width: "180px",
                height: "48px",
                alignSelf: "center",
              }}
            >
              <Button
                disabled={Object.values(answers).some((qs) => !qs[0])}
                onClick={() => {
                  props.onSubmit();
                  props.onClose();
                }}
                type="primary"
                style={{ width: "180px", height: "48px" }}
              >
                Apply
              </Button>
            </Container>
          }
        </Container>
      </Popup>
    </>
  );
};
