import React from "react";
import { Container, Icon } from "..";
import { getIconPropsForPlan } from "../../utils";

import css from "./style.module.scss";

const metalLevelColors = {
  Bronze: "#6A3805",
  Catastrophic: "#575988",
  Gold: "#AF9500",
  Platinum: "#3B3B41",
  Silver: "#B4B4B4",
};

export default (props) => {
  return (
    <Container className={css.planData}>
      <Container className={css.icon}>
        <Icon {...getIconPropsForPlan(props.plan)} />
      </Container>
      <Container className={css.planDataInfo}>
        <Container className={css.carrierName}>
          {props.plan.carrier.value}
        </Container>
        <Container className={css.planName}>
          {props.plan.plan_name.value}
        </Container>
      </Container>
      <Container className={css.premium}>
        $ {props.plan.premium.value?.toFixed(2)}
      </Container>
    </Container>
  );
};
