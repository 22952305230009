import React, { useState } from "react"

import { CaretDownOutlined, CloseOutlined, Container, Dropdown, FileUnknownOutlined, Header, MenuOutlined } from "..";
import agents from "../quotes-panel/components/profile/agents";

import css from "./style.module.scss"

import { Menu } from "antd"

import { connect } from "react-redux";

const MenuRaw = ({ selectedAgent, setSelectedAgent }) => {
    const [open, setOpen] = useState(false);

    return <Container className={`hidden-on-web ${css.mobileMenuContainer}`}>
        <Container onPress={() => { setOpen(!open) }} className={css.mobileMenuIconContainer}>
            <MenuOutlined />
        </Container>
        <Container className={`${css.mobileMenuContent} ${open ? css.mobileMenuContentOpen : css.mobileMenuContentClosed}`}>
            <Container className={css.closeButton}><CloseOutlined onClick={() => { setOpen(false) }} /> </Container>
            <Container className={css.mobileMenu}>
                <a target="_blank" href="https://docs.google.com/presentation/d/16pLZNe3V6zv5WoQqqlhS2mUNqOUZjHLHYjjmVeSmtio/present?slide=id.p"><Container className={css.mobileMenuOption} >
                    <Container style={{ color: "#0000EE" }} className={css.mobileMenuOptionLabel} >E-manual</Container>
                    <Container style={{ color: "#0000EE" }} className={css.mobileMenu} > <FileUnknownOutlined /> </Container>
                </Container></a>


                {selectedAgent != undefined ? <Container onPress={() => {
                    localStorage.removeItem("agent");
                    setSelectedAgent(null);
                }} className={css.mobileMenuOption}>
                    <Container className={css.mobileMenuOptionLabel}>{selectedAgent}</Container>
                    <Container><CloseOutlined /></Container>
                </Container> : <Dropdown
                    trigger={["click"]}

                    overlay={


                        <Menu className={css.agentsMenu}>
                            {
                                agents.map(a => {
                                    return <Menu.Item onClick={() => {
                                        localStorage.setItem("agent", a.firstName + " " + a.lastName)
                                        setSelectedAgent(a.firstName + " " + a.lastName);
                                    }} style={{ paddingLeft: "24px" }} key={"agent-" + a.firstName + a.lastName}>
                                        {a.firstName + " " + a.lastName}

                                    </Menu.Item>
                                })
                            }

                        </Menu>
                    }
                >
                    <Container className={css.mobileMenuOption} >
                        <Container className={css.mobileMenuOptionLabel} >
                            Select an agent
                        </Container>
                        <Container className={css.mobileMenuOptionIcon} >
                            <CaretDownOutlined />
                        </Container>
                    </Container>
                </Dropdown>
                }

            </Container>
        </Container>
    </Container>
}

const mapStateToProps = ({selectedAgent}) => {
    return { 
        selectedAgent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedAgent: (payload) =>
            dispatch({ type: `SET_SELECTED_AGENT`, payload }),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(MenuRaw)