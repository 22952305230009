import React, { useState } from "react";
import { Slider } from "antd";

import { Container, Icon, TextInput } from "../..";
import { handleInputChange } from "../../../utils";
import css from "./style.module.scss";

export default ({
  min = 0,
  max = 100,
  precision = 1,
  setValue,
  value1,
  setValue1,
  value2,
  setValue2,
  icon,
  label,
  unit,
  unit2,
}) => {
  return (
    <Container className={css.conversionSliderContainer}>
      <Container className={css.iconTitleAndValue}>
        <Container className={css.iconAndTitle}>
          <Container className={css.icon}>
            <Icon icon={icon} />
          </Container>
          <Container>{label}</Container>
        </Container>
        <Container className={css.doubledInputs}>
          <TextInput
            style={{ boxShadow: "unset", width: "120px", height: "36px" }}
            type="number"
            onChange={(v) => {
              setValue1(handleInputChange(v));
            }}
            placeholder={unit}
            value={value1}
            focusedlabel={unit}
            notenrollment
          />
          <Container className={css.di}>
            <TextInput
              type="number"
              onChange={(v) => {
                setValue2(handleInputChange(v));
              }}
              placeholder={unit2}
              value={value2}
              style={{ boxShadow: "unset", width: "120px", height: "36px" }}
              focusedlabel={unit2}
              notenrollment
            />
          </Container>
        </Container>
      </Container>

      <Slider
        tooltipVisible={false}
        tipFormatter={(value) => {
          return `${value.toFixed(precision)} ${value.toFixed(
            precision
          )} ${unit}`;
        }}
        min={min}
        max={max}
        onChange={(v) => {
          setValue(v);
        }}
        value={Number(value1) + Number(value2) / 12}
        step={0.1}
      />
      <Container className={css.marks}>
        <Container className={css.Marks}>
          <Container className={css.minMark}>
            <Container className={css.markValue}>{min.toFixed(0)} </Container>
            <Container className={css.markUnit}>{unit}</Container>
          </Container>
        </Container>
        <Container className={css.maxMarks}>
          <Container className={css.maxMark}>
            <Container className={css.markValue}>{max.toFixed(0)} </Container>
            <Container className={css.markUnit}>{unit}</Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
