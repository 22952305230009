const getIconPropsForPlan = (plan) => {
    const info = plan.info;
    let iconProps = {};
    const metalLevelColors = {
        Bronze: "#6A3805",
        Catastrophic: "#575988",
        Gold: "#AF9500",
        Platinum: "#3B3B41",
        Silver: "#B4B4B4",
    };


    if (info && info.label == "Metal Level")
        iconProps = { icon: "star-flag", fill: metalLevelColors[info.value] };
    if (info && info.value == "Association")
        iconProps = { icon: "association", fill: "#F7971C" };
    if (info && info.value == "NHICSupplemental")
        iconProps = { icon: "shield-filled", fill: "#F7971C" };
    if (info && info.value == "STM")
        iconProps = { icon: "shield-outlined", fill: "#F7971C" };
    if (info && info.value == "Basic")
        iconProps = { icon: "s-outlined", fill: "#1B75BC" };
    if (info && info.value == "Crown")
        iconProps = { icon: "crown", fill: "#1B75BC" };
    if (info && info.value == "Enhanced")
        iconProps = { icon: "s-filled", fill: "#1B75BC" };
    if (info && info.value == "Short Term Health Insurance")
        iconProps = { fill: "#575988", icon: "short-term" };
    if (info && info.value == "Dental Insurance")
        iconProps = { icon: "dental-insurance", fill: "#053555" };
    if (info && info.value == "HealthiestYou")
        iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
    if (info && info.value == "Hospital Indemnity")
        iconProps = { icon: "hospital-indemnity", fill: "#FF3D3D" };
    if (info && info.value == "Supplemental Indemnity")
        iconProps = { icon: "supplemental", fill: "#F3BF38" };
    if (info && info.value == "HealthiestYou")
        iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
    if (info && info.value == "Term Life")
        iconProps = { icon: "term-life", fill: "#1B75BC" };
    if (info && info.value == "Vision")
        iconProps = { icon: "vision", fill: "#1C4C99" };
    if (info && info.value == "Accident")
        iconProps = { icon: "accident", fill: "#F7971C" };
    if (info && info.value == "Critical Illness")
        iconProps = { icon: "critical-illness", fill: "#77C3FF" };
    if (info && info.value == "ACSM")
        iconProps = { icon: "universal-acsm", fill: "#fa6647" };
    if (info && info.value == "Standard")
        iconProps = { icon: "universal", fill: "#FCBF45" };
    if (info && info.value == "SmartShare")
        iconProps = { icon: "universal-smart-share", fill: "#212282" };
    if (plan.plan_name.value == "Aspire Dental")
        iconProps = { icon: "aspire-dental", fill: "#77C3FF" };
    return iconProps;
}


export {
    getIconPropsForPlan
}