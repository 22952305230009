import React, { useState } from "react";
import { Badge, Checkbox, Container, MinMaxSlider } from "../../../../";
import css from "./style.module.scss";
import { v4 as uuidv4 } from "uuid";
import { Link } from "gatsby";

import CheckboxFilterGroup from "./checkbox-filter-group";
import MinMaxFilterGroup from "./minmax-filter-group";
import Sorter from "../sorter";

const FilterGroupRaw = (props) => {
  const { carrierKey, label, values, style } = props;

  let group_type =
    values[0]?.type == "MIN_MAX_SLIDER" ? "MIN_MAX_SLIDERS" : "CHECKBOX";

  return (
    <Container className={css.filterGroupContainer} style={style}>
      {label == "Price Range" && (
        <Container>
          <Sorter />
        </Container>
      )}
      <Container className={css.title}>{label}</Container>
      <Container
        className={`${
          group_type === "MIN_MAX_SLIDERS" ? css.sliders : css.items
        }`}
      >
        {group_type === "MIN_MAX_SLIDERS" && (
          <MinMaxFilterGroup carrierKey={carrierKey} values={values} />
        )}
        {group_type === "CHECKBOX" && (
          <CheckboxFilterGroup carrierKey={carrierKey} values={values} />
        )}
      </Container>
    </Container>
  );
};

export default FilterGroupRaw;
